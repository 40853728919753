import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import { Box, Heading, Flex, Text } from "rebass/styled-components";
import Forms from "../../Forms";
import Map from "../../UI/Map";
import Icon from "../../UI/Icon";

function isString(str) {
  if (str && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
}

const FormMap = ({ data }) => {
  const hasData = data.form ? !isString(data.form) : false;
  const fluidContainer = data.blockSettings ? data.blockSettings.containerFluid : false;
  var telLink = "tel:+687";
  var mailTo = "mailto:";
  return (
    <Wrapper
      data={data}
      containerProps={{
        alignItems: "stretch",
        display: "flex !important",
        px: fluidContainer ? "10px !important" : ""
      }}
    >
      {hasData && (
        <Flex variant="grid" px={[2, 2, 0]}>
          <Flex
            variant="gridItem"
            alignItems="center"
            width={[1, 1, 1 / 2]}
            px={fluidContainer ? [2, 2, 5] : ""}
          >
            <Flex alignItems="center" pl={fluidContainer ? [0, 0, 5] : ""}>
              <Forms data={data.form.frontmatter} />
            </Flex>
          </Flex>
          <Box
            variant="gridItem"
            width={[1, 1, 1 / 2]}
            height={["600px", "800px", "100%"]}
            px={fluidContainer ? [2, 2, 5] : ""}
          >
            <Box sx={{ position: "relative" }} height="100%" pr={fluidContainer ? [0, 0, 5] : ""}>
              <Map position={data.position} />
              {data.showInfo && (
                <Box
                  backgroundColor="white"
                  sx={{ position: "absolute", bottom: 6, left: 6 }}
                  p={4}
                >
                  <Flex mb={4}>
                    <Box color="primary" pr={2}>
                      <Icon icon="poi" size={20} />
                    </Box>
                    <Box sx={{ "p:first-child": { mt: 0 }, "p:last-child": { mb: 0 } }}>
                      <Markdown>{data.address}</Markdown>
                    </Box>
                  </Flex>
                  <Flex mb={4}>
                    <Box color="primary" pr={2}>
                      <Icon icon="clock" size={20} />
                    </Box>
                    <Box sx={{ "p:first-child": { mt: 0 }, "p:last-child": { mb: 0 } }}>
                      <Markdown>{data.openTime}</Markdown>
                    </Box>
                  </Flex>
                  <a href={telLink + (data.phone ? data.phone.replace(/[- )(]/g, "") : "")}>
                    <Flex alignItems="center" mb={4}>
                      <Flex alignItems="center" color="primary" pr={2}>
                        <Icon icon="phone" size={20} />
                      </Flex>
                      <Text>{data.phone}</Text>
                    </Flex>
                  </a>
                  <a href={mailTo + data.email}>
                    <Flex alignItems="center">
                      <Flex alignItems="center" color="primary" pr={2}>
                        <Icon icon="mail" size={20} />
                      </Flex>
                      <Text>{data.email}</Text>
                    </Flex>
                  </a>
                </Box>
              )}
            </Box>
          </Box>
        </Flex>
      )}
      {!hasData && (
        <Box>
          <Text>Formulaire + carte Non disponible en previsualisation</Text>
        </Box>
      )}
    </Wrapper>
  );
};

FormMap.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.any,
    blockSettings: PropTypes.shape({
      containerFluid: PropTypes.any
    }),
    cardTitle: PropTypes.any,
    openTime: PropTypes.any,
    email: PropTypes.any,
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    }),
    phone: PropTypes.any,
    position: PropTypes.any,
    showInfo: PropTypes.any
  })
};
export default FormMap;

export const query = graphql`
  fragment FormMapBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        successMessage
        elements {
          imputType
          label
          name
          printLabel
          placeholder
          required
          type
          default
          multiple
          options {
            value
            label
          }
        }
      }
    }
    position
    address
    openTime
    cardTitle
    phone
    email
    showInfo
  }
`;
