import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Button, Box } from "rebass/styled-components";

const Modal = ({ handleClose, children, isOpen, bypassBackgroundClose }) => {
  bypassBackgroundClose = bypassBackgroundClose || false;
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    setTimeout(
      function() {
        setOpened(isOpen);
      },
      isOpen ? 0 : 300
    );
  }, [isOpen]);
  return (
    <Box
      sx={{
        display: isOpen ? "block" : opened ? "block" : "none",
        opacity: isOpen && opened ? 1 : 0,
        transition: "all 300ms ease",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: "9999",
        background: "rgba(0, 0, 0, 0.3)"
      }}
    >
      {!bypassBackgroundClose && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
            background: "transparent"
          }}
          onClick={handleClose}
        />
      )}
      <Box
        sx={{
          width: "auto",
          height: "auto",
          backgroundColor: "transparent",
          position: "absolute",
          zIndex: 1,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "scroll",
          maxHeight: "100%",
          maxWidth: "100%",
          "-ms-overflow-style": "none" /* IE and Edge */,
          "scrollbar-width": "none" /* Firefox */,
          "::-webkit-scrollbar": {
            display: "none"
          }
        }}
      >
        <Button
          onClick={handleClose}
          variant="ninja"
          color="text"
          sx={{
            position: "absolute",
            top: 0,
            right: [2, 2, 5],
            zIndex: 1
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="butt"
            strokeLinejoin="arcs"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </Button>
        {children}
      </Box>
    </Box>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  handleClose: PropTypes.any,
  isOpen: PropTypes.any,
  bypassBackgroundClose: PropTypes.bool
};

export default Modal;
