import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { useStaticQuery, graphql } from "gatsby";
import styled, { withTheme } from "styled-components";
const MapWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 400px;

  #contact-map-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    #contact-map {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  .mapboxgl-control-container {
    display: none;
  }
`;

const Map = ({ position, theme }) => {
  const { markdownRemark } = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { settingName: { eq: "siteinfos" } }) {
        frontmatter {
          mapboxtoken
        }
      }
    }
  `);
  position = JSON.parse(position);
  const mapSettings = {
    apiKey: markdownRemark.frontmatter.mapboxtoken,
    map: {
      center: position.coordinates,
      offset: [0, 0], //h,v
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: 14,
      scrollZoom: false,
      interactive: false,
    },
  };

  const mapContainer = useRef(null);
  const [map, setMap] = useState(false);
  const [mapboxLoaded, setMapboxLoaded] = useState(false);
  const onScreen = useOnScreen(mapContainer, "300px");

  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      if (typeof mapboxgl !== "undefined" && !map) {
        mapboxgl.accessToken = mapSettings.apiKey;
        const map = new mapboxgl.Map({ container: mapContainer.current, ...mapSettings.map });
        map.flyTo({
          center: mapSettings.map.center,
          zoom: mapSettings.map.zoom,
          offset: window.innerWidth >= 10 ? mapSettings.map.offset : [0, 0],
          maxDuration: 1,
        });
        new mapboxgl.Marker({
          color: theme.colors.primary,
        })
          .setLngLat(mapSettings.map.center)
          .addTo(map);
        window.onresize = function () {
          map.flyTo({
            center: mapSettings.map.center,
            zoom: mapSettings.map.zoom,
            offset: window.innerWidth >= 10 ? mapSettings.map.offset : [0, 0],
            maxDuration: 1,
          });
        };
        map.on("load", () => {
          setMap(map);
          map.resize();
        });
      }
    };
    if (!mapboxLoaded && onScreen) {
      if (typeof mapboxgl === "undefined") {
        var head = document.getElementsByTagName("head")[0];
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.js";
        script.onload = function () {
          initializeMap({ setMap, mapContainer });
        };
        head.appendChild(script);

        setMapboxLoaded(true);
      } else {
        initializeMap({ setMap, mapContainer });
      }
    }
  }, [map, onScreen, mapboxLoaded]);

  return (
    <MapWrapper>
      <div id="contact-map-wrapper">
        <div ref={(el) => (mapContainer.current = el)} id="contact-map" />
      </div>
    </MapWrapper>
  );
};

Map.propTypes = {
  position: PropTypes.string,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.string,
    }),
  }),
};

export default withTheme(Map);

// Hook
function useOnScreen(ref, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}
