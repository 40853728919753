import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading, Flex, Button } from "rebass/styled-components";

const ContentTwoCol = ({ data }) => {
  return (
    <Wrapper data={data}>
      {data.title && (
        <Heading as="h2" variant="blockTitle">
          {data.title}
        </Heading>
      )}
      {data.intro && (
        <Box maxWidth="1000px" mx="auto" textAlign="center">
          <Markdown>{data.intro}</Markdown>
        </Box>
      )}
      <Flex variant="grid">
        {data.rows.map((item, i) => (
          <Box key={i} width={[1, 1, 1 / 2]} variant="gridItem" pt={11}>
            <Box
              sx={{
                "& > div > ul:last-child": {
                  mb: 0,
                  "li:last-child": {
                    mb: 0
                  }
                },
                "p:last-child": {
                  mb: 0
                },
                "h3, h4": {
                  "&:first-child": {
                    mt: 0
                  }
                }
              }}
            >
              <Box mb={1}>
                <CustomImage img={item.image} />
              </Box>
              {item.title && (
                <Heading as="h3" variant="h3">
                  {item.title}
                </Heading>
              )}
              <Markdown>{item.content}</Markdown>
            </Box>
          </Box>
        ))}
      </Flex>
    </Wrapper>
  );
};

ContentTwoCol.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    intro: PropTypes.any,
    rows: PropTypes.any
  })
};

export default ContentTwoCol;

export const query = graphql`
  fragment ContentTwoColBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    intro
    rows {
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      title
      content
    }
  }
`;
