import PropTypes from "prop-types";
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import Markdown from "../UI/Markdown";
import { Label, Input, Select, Textarea, Radio, Checkbox } from "@rebass/forms/styled-components";
import { Box, Flex, Text, Button } from "rebass/styled-components";
// import loadable from "@loadable/component";

// const EpayInput = loadable(() => import("./EpayInput"));
// const FileInput = loadable(() => import("./FileInput"));

export const encodeData = data => {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
};
const isValidEmail = email => {
  var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return email.search(emailRegEx) !== -1;
};
const Form = forwardRef((props, ref) => {
  const defaultSendAction = (valueToSend, e) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      body: encodeData(valueToSend)
    })
      .then(() => submitSuccess())
      .catch(error => alert(error));
  };
  const data = props.data || { formId: "", elements: [], successMessage: "" };
  const sendAction = props.sendAction || defaultSendAction;
  const [messages, setMessages] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [hasError, setHasError] = useState(false);
  const [itemsError, setItemsError] = useState([]);
  const [sent, setSent] = useState(false);
  const setDefaultValues = () => {
    if (data) {
      const formValuesTemp = {};
      data.elements.map(formElement => {
        if (
          formElement.type !== "markdown" &&
          formElement.type !== "submit" &&
          formElement.type !== "recaptcha"
        ) {
          formValuesTemp[formElement.name] = formElement.default || "";
        }
        return null;
      });
      setFormValues({ ...formValuesTemp });
    }
  };
  useEffect(() => {
    setDefaultValues();
  }, []);
  useImperativeHandle(ref, () => ({
    submitSuccess() {
      submitSuccess();
    }
  }));
  if (!props.data) {
    return (
      <Box>
        <Text>Formulaire Non disponible en previsualisation</Text>
      </Box>
    );
  }

  //let captchaRef = useRef(null);

  const hasRecaptcha = () => {
    return data.elements.find(element => element.type === "recaptcha" && element.default);
  };

  const hasEpay = () => {
    return data.elements.find(element => element.type === "Epaync");
  };
  const handleChange = (valueName, value) => {
    setFormValues({
      ...formValues,
      [valueName]: value
    });
    if (itemsError.indexOf(valueName) >= 0) {
      const newMessages = [...messages].filter(message => message.name !== valueName);
      var tempError = [...itemsError].filter(name => name !== valueName);
      var hasTempError = hasError;
      data.elements.map(function(formElement) {
        if (formElement.name === valueName) {
          var currentValue = value;
          if (formElement.required && currentValue === "" && formElement.type !== "recaptcha") {
            hasTempError = true;
            tempError.push(formElement.name);
            newMessages.push({
              name: formElement.name,
              message: 'Le champ "' + formElement.label + '" est requis.'
            });
          }
          if (formElement.type === "text") {
            if (formElement.imputType === "email") {
              if (!isValidEmail(currentValue)) {
                tempError.push(formElement.name);
                hasTempError = true;
                newMessages.push({
                  name: formElement.name,
                  message: "Email non valide."
                });
              }
            }
          }
        }

        return "";
      });
      setMessages(newMessages);
      setHasError(hasTempError);
      setItemsError(tempError);
    }
  };

  const handleSubmit = e => {
    const newMessages = [];
    var tempError = [];
    var hasTempError = false;
    const captchaValue = hasRecaptcha()
      ? typeof grecaptcha !== "undefined"
        ? grecaptcha.getResponse()
        : false
      : false;

    data.elements.map(function(formElement) {
      var currentValue = formValues[formElement.name] || "";
      if (formElement.required && currentValue === "" && formElement.type !== "recaptcha") {
        hasTempError = true;
        tempError.push(formElement.name);
        newMessages.push({
          name: formElement.name,
          message: 'Le champ "' + formElement.label + '" est requis.'
        });
      }
      if (formElement.type === "text") {
        if (formElement.imputType === "email") {
          if (!isValidEmail(currentValue)) {
            tempError.push(formElement.name);
            hasTempError = true;
            newMessages.push({
              name: formElement.name,
              message: "Email non valide."
            });
          }
        }
      }
      return "";
    });
    const myForm = e.target;
    const formData = new FormData(myForm);
    formData.forEach((value, key) => (formData[key] = value));
    console.log(formData);
    if (!formData["g-recaptcha-response"] && hasRecaptcha()) {
      hasTempError = true;
      newMessages.push({
        name: "recaptcha",
        message: "Veuillez confirmer que n'êtes pas un robot"
      });
    }
    setMessages(newMessages);
    setHasError(hasTempError);
    setItemsError(tempError);
    if (!hasTempError) {
      const valueToSend = {
        "form-name": data.formId,
        ...formValues
      };
      if (hasRecaptcha()) {
        valueToSend["g-recaptcha-response"] = formData["g-recaptcha-response"];
      }
      sendAction(valueToSend, e);
    } else {
      e.preventDefault();
    }
  };
  const submitSuccess = () => {
    setFormValues({});
    if (data.successMessage) {
      setMessages([{ name: "", message: data.successMessage }]);
    }
    setSent(true);
    setTimeout(function() {
      setMessages([]);
      setSent(false);
    }, 3000);
  };
  const replaceTokens = text => {
    for (const id in formValues) {
      var re = new RegExp("{" + id + "}", "g");
      text = text.replace(re, formValues[id]);
    }
    return text;
  };

  const formProps = hasRecaptcha()
    ? {
        "data-netlify": "true",
        "data-netlify-recaptcha": "true"
      }
    : {
        "data-netlify": "true"
      };
  if (hasEpay()) {
    formProps.action = "https://epaync.nc/vads-payment/";
    formProps.method = "post";
  }
  return (
    <Box>
      <form
        onSubmit={e => {
          handleSubmit(e);
        }}
        name={data.formId}
        id={data.formId}
        {...formProps}
      >
        <input type="hidden" name="form-name" aria-label="form-name" value={data.formId}></input>
        {!sent && (
          <Flex flexWrap="wrap" mx={-2}>
            {data.elements.map((formElement, i) => {
              formElement.options = formElement.options || [{ value: "" }];
              formElement.width = formElement.width || "100%";
              formElement.width = formElement.width.replace(/\s/g, "");
              formElement.default = formElement.default || "";
              return (
                <Box
                  key={"form-el-" + i}
                  pb={5}
                  px={2}
                  width={formElement.width.split(",")}
                  variant={itemsError.indexOf(formElement.name) < 0 ? "" : "formItemError"}
                >
                  {formElement.type === "text" && (
                    <>
                      {formElement.label && formElement.printLabel && (
                        <Label htmlFor={formElement.name}>
                          {formElement.label}
                          {formElement.required && <span className="required-mark">*</span>}
                        </Label>
                      )}
                      <Input
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        placeholder={formElement.placeholder}
                        onChange={e => {
                          handleChange(formElement.name, e.target.value);
                        }}
                      />
                    </>
                  )}
                  {formElement.type === "textarea" && (
                    <>
                      {formElement.label && formElement.printLabel && (
                        <Label htmlFor={formElement.name}>
                          {formElement.label}
                          {formElement.required && <span className="required-mark">*</span>}
                        </Label>
                      )}
                      <Textarea
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        placeholder={formElement.placeholder}
                        rows="6"
                        onChange={e => {
                          handleChange(formElement.name, e.target.value);
                        }}
                      />
                    </>
                  )}
                  {formElement.type === "radio" && (
                    <Box
                      sx={{
                        "input:checked ~ .checkit": {
                          display: "none"
                        },
                        "input:checked ~ .checkit:nth-child(2)": {
                          display: "block"
                        }
                      }}
                    >
                      {formElement.label && formElement.printLabel && (
                        <Label htmlFor={formElement.name}>
                          {formElement.label}
                          {formElement.required && <span className="required-mark">*</span>}
                        </Label>
                      )}
                      {formElement.options.map((option, iOption) => (
                        <Label key={iOption}>
                          <Radio
                            className="checkit"
                            id={formElement.name + "-" + iOption}
                            name={formElement.name}
                            value={option.value}
                            onChange={e => {
                              handleChange(
                                formElement.name,
                                e.target.checked ? e.target.value : ""
                              );
                            }}
                            defaultChecked={option.value === formElement.default}
                          />
                          <Box sx={{ lineHeight: 1.5, flex: 1, p: { m: 0 } }}>
                            <Markdown>{replaceTokens(option.label)}</Markdown>
                          </Box>
                        </Label>
                      ))}
                    </Box>
                  )}
                  {formElement.type === "checkbox" && (
                    <Box
                      sx={{
                        "input:checked ~ .checkit": {
                          display: "none"
                        },
                        "input:checked ~ .checkit:nth-child(2)": {
                          display: "block"
                        }
                      }}
                    >
                      {formElement.label && formElement.printLabel && (
                        <Label htmlFor={formElement.name}>
                          {formElement.label}
                          {formElement.required && <span className="required-mark">*</span>}
                        </Label>
                      )}
                      {formElement.options.map((option, iOption) => (
                        <Label key={iOption}>
                          <Checkbox
                            className="checkit"
                            id={formElement.name + "-" + iOption}
                            name={formElement.name}
                            value={option.value}
                            onChange={e => {
                              var currentValue = formValues[formElement.name] || "";
                              var currentValueArray = currentValue.split(",");
                              if (currentValueArray.indexOf(e.target.value) !== -1) {
                                currentValueArray.splice(
                                  currentValueArray.indexOf(e.target.value),
                                  1
                                );
                              }
                              if (e.target.checked) {
                                currentValueArray.push(e.target.value);
                              }
                              handleChange(
                                formElement.name,
                                currentValueArray.filter(v => v !== "").join(",")
                              );
                            }}
                            defaultChecked={option.value === formElement.default}
                            color="gray"
                          />
                          <Box sx={{ lineHeight: 1.5, flex: 1, p: { m: 0 } }}>
                            <Markdown>{replaceTokens(option.label)}</Markdown>
                          </Box>
                        </Label>
                      ))}
                    </Box>
                  )}
                  {formElement.type === "select" && (
                    <>
                      {formElement.label && formElement.printLabel && (
                        <Label htmlFor={formElement.name}>
                          {formElement.label}
                          {formElement.required && <span className="required-mark">*</span>}
                        </Label>
                      )}
                      <Select
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        onChange={e => {
                          handleChange(
                            formElement.name,
                            e.target.selectedOptions ? e.target.value : ""
                          );
                        }}
                      >
                        {formElement.options.map((optionObj, iOption) => (
                          <option key={iOption} value={optionObj.value}>
                            {optionObj.label}
                          </option>
                        ))}
                      </Select>
                    </>
                  )}
                  {/* {formElement.type === "file" && (
                    <FileInput
                      formElement={formElement}
                      onChange={(valueName, value) => {
                        setFormValues({
                          ...formValues,
                          [valueName]: value
                        });
                      }}
                    />
                  )} */}
                  {formElement.type === "recaptcha" && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: '<div data-netlify-recaptcha="true"></div>'
                      }}
                    />
                  )}
                  {formElement.type === "markdown" && (
                    <Box sx={{ lineHeight: 1.5, flex: 1, p: { m: 0 } }}>
                      <Markdown>{replaceTokens(formElement.default)}</Markdown>
                    </Box>
                  )}
                  {/* {formElement.type === "Epaync" && <EpayInput formElementInput={formElement} />} */}
                  {formElement.type === "hidden" && (
                    <input type="hidden" name={formElement.name} value={formElement.default} />
                  )}
                  {formElement.type === "submit" && (
                    <Button type="submit" width="100%">
                      {formElement.label}
                    </Button>
                  )}
                </Box>
              );
            })}
          </Flex>
        )}
      </form>
      {messages.length > 0 && (
        <Box bg={hasError ? "danger" : "success"} p={3} color="white">
          {messages.map((message, index) => (
            <Text key={`contact-form-message-${index}`}>{message.message}</Text>
          ))}
        </Box>
      )}
    </Box>
  );
});

Form.propTypes = {
  data: PropTypes.shape({
    elements: PropTypes.array,
    formId: PropTypes.string,
    successMessage: PropTypes.string
  }),
  sendAction: PropTypes.any
};
export default Form;
