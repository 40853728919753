import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";
import { Flex, Box, Heading, Button } from "rebass/styled-components";
import Link from "../../UI/Link";
import blockcollection from "../../../cms/data/settings/blockcollections.json";
import { withTheme } from "styled-components";

const Banner = ({ data, theme }) => {
  var bgImgDefaultUrl = "";
  var elemFields = [];
  blockcollection.blocks.map(function(elem, index) {
    if (elem.name === "Banner") {
      elemFields = elem.fields;
    }
  });
  elemFields.map(function(item, index) {
    {
      item.type === "image";
      bgImgDefaultUrl = item.default;
    }
  });
  return (
    <Wrapper data={data} py={[0, 0, 0]} bg="gray" sx={{ position: "relative" }}>
      <Flex
        py={[9, 10, 11]}
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        variant="grid"
        sx={{
          py: [10, 10, 16],
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: "50%",
            width: "102vw",
            height: "100%",
            transform: "translateX(-50%)",
            backgroundImage: "url(" + data.bgImg.childImageSharp.fluid.srcWebp + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            zIndex: 1
          },
          "&:after": {
            content: '""',
            position: "absolute",
            zIndex: 1,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            backgroundImage:
              "radial-gradient(circle, " +
              theme.colors.primaryHover +
              "99 0%, " +
              theme.colors.primaryHover +
              "99 100%)"
          },
          "& > *": {
            position: "relative",
            zIndex: 3,
            color: "white"
          }
        }}
      >
        <Box width={[1, 1, 1, 3 / 4]} variant="gridItem" sx={{ "p:last-child": { mb: 0 } }}>
          <Flex flexDirection="column" justifyContent="center" height="100%">
            {data.title && (
              <Heading as="h2" variant="h2" my={0} color="white">
                {data.title}
              </Heading>
            )}
            {data.content && (
              <Box mt={3}>
                <Markdown>{data.content}</Markdown>
              </Box>
            )}
          </Flex>
        </Box>
        <Flex
          mt={[4, 4, 4, 0]}
          width={[1, 1, 1, 1 / 4]}
          justifyContent={["flex-start", "flex-start", "flex-start", "flex-end"]}
          alignItems="center"
          variant="gridItem"
        >
          <Link href={data.cta.href}>
            <Button variant="white">{data.cta.label}</Button>
          </Link>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

Banner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    content: PropTypes.any,
    cta: PropTypes.any,
    bgImg: PropTypes.any
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primaryHover: PropTypes.any
    })
  })
};

export default withTheme(Banner);

export const query = graphql`
  fragment BannerBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    content
    cta {
      href
      label
    }
    bgImg {
      publicURL
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
