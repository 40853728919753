import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Box, Flex, Text, Heading } from "rebass/styled-components";
import Forms from "../../Forms";

function isString(str) {
  if (str && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
}

const FormImg = ({ data }) => {
  const hasData = data.form ? !isString(data.form) : false;
  const bgImgUrl = data.imageContact.childImageSharp.fluid.src || "";
  return (
    <Wrapper data={data}>
      {hasData && (
        <Flex
          variant="grid"
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              display: ["none", "none", "none", "block"],
              width: "50vw",
              height: "100%",
              position: "absolute",
              left: "50%",
              ml: [2, 2, 3],
              top: 0,
              // backgroundSize: "initial",
              backgroundPosition: "left top",
              backgroundRepeat: "no-repeat",
              backgroundImage: "url(" + bgImgUrl + ")"
            }
          }}
        >
          <Box width={["100%", "100%", "100%", "50%"]} variant="gridItem">
            <Heading as="h2" variant="blockTitle" mx="0">
              Contact
            </Heading>
            <Forms data={data.form.frontmatter} />
          </Box>
        </Flex>
      )}
      {!hasData && (
        <Box>
          <Text>Formulaire + carte Non disponible en previsualisation</Text>
        </Box>
      )}
    </Wrapper>
  );
};

FormImg.propTypes = {
  data: PropTypes.shape({
    imageContact: PropTypes.any,
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    })
  })
};
export default FormImg;

export const query = graphql`
  fragment FormImgBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        successMessage
        elements {
          imputType
          label
          name
          printLabel
          placeholder
          required
          type
          default
          multiple
          options {
            value
            label
          }
        }
      }
    }
    imageContact {
      publicURL
      childImageSharp {
        fluid(maxWidth: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
