import PropTypes from "prop-types";
import React, { useState } from "react";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import { Label, Input } from "@rebass/forms/styled-components";
import { Flex, Box, Heading, Text, Button } from "rebass/styled-components";
import sendinblue from "./sendinblue";

const isValidEmail = email => {
  var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return email.search(emailRegEx) !== -1;
};
const NewsletterRegister = ({ data }) => {
  const [email, setEmail] = useState("");
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState([]);

  const handleChange = value => {
    setEmail(value);
  };
  const handleSubmit = event => {
    event.preventDefault();
    var hasTempError = false;
    if (email === "") {
      setHasError(true);
      hasTempError = false;
      setMessage("Le champ email est requis.");
      return;
    }
    if (email != "" && !isValidEmail(email)) {
      setHasError(true);
      hasTempError = false;
      setMessage("Email non valide.");
      return;
    }
    setHasError(hasTempError);
    if (!hasTempError) {
      setHasError(false);
      sendinblue(email,submitSuccess,alert);
    }
  };
  const submitSuccess = () => {
    setMessage("Vous êtes maintenant abonné à la newsletter");
  };
  return (
    <Wrapper data={data}>
      <Flex variant="grid" alignItems="center">
        <Box width={[1, 1, 1, 1 / 2]} variant="gridItem">
          {data.title && (
            <Heading as="h2" variant="blockTitle" mx={["auto", "auto", "0"]}>
              {data.title}
            </Heading>
          )}
          {data.intro && (
            <Box maxWidth="1000px" mx="auto">
              <Markdown>{data.intro}</Markdown>
            </Box>
          )}
        </Box>
        <Box width={[1, 1, 1, 1 / 2]} mt={[4, 4, 4, 0]} variant="gridItem">
          <form
            onSubmit={event => {
              handleSubmit(event);
            }}
          >
            <Flex>
              <Box sx={{ flex: "1" }}>
                <Input
                  id="emailchimp"
                  name="emailchimp"
                  placeholder="Adresse email"
                  onChange={event => {
                    handleChange(event.target.value);
                  }}
                  sx={{
                    borderColor: "primary",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0"
                  }}
                />
              </Box>
              <Button
                type="submit"
                variant="base"
                sx={{
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  flex: "0 0 auto"
                }}
              >
                <Text>S’inscrire</Text>
              </Button>
            </Flex>
          </form>
          {message.length > 0 && (
            <Box bg={hasError ? "danger" : "success"} mt={2} p={3} color="white">
              <Text>{message}</Text>
            </Box>
          )}
        </Box>
      </Flex>
    </Wrapper>
  );
};

NewsletterRegister.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    intro: PropTypes.any
  })
};

export default NewsletterRegister;

export const query = graphql`
  fragment NewsletterRegisterBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    intro
  }
`;
