import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import Link from "../../UI/Link";
import { Box, Heading, Flex, Button, Text } from "rebass/styled-components";

const Offers = ({ data }) => {
  return (
    <Wrapper data={data}>
      {data.title && (
        <Heading as="h2" variant="blockTitle">
          {data.title}
        </Heading>
      )}
      {data.intro && (
        <Box maxWidth="1000px" mx="auto" textAlign="center">
          <Markdown>{data.intro}</Markdown>
        </Box>
      )}
      <Flex variant="grid" pt={5}>
        {data.offers.map((offer, i) => (
          <Box key={i} width={[1, 1, 1 / 2]} variant="gridItem" mt={[4, 4, 6]}>
            <Box variant="card">
              <Box
                p={5}
                sx={{
                  borderWidth: "0 0 2px 0",
                  borderStyle: "solid",
                  borderColor: "grayLight"
                }}
              >
                <Heading
                  as="h3"
                  variant="h3"
                  mb="0"
                  sx={{
                    "&:after": {
                      display: "none"
                    },
                    fontSize: "24px !important",
                  }}
                >
                  {offer.offerTitle}
                </Heading>
                {offer.offerDuration && <Text>{offer.offerDuration}</Text>}
              </Box>
              <Flex flexDirection={["column", "row", "column", "column", "row"]}>
                <Box
                  width={["100%", "55%", "100%", "55%"]}
                  maxWidth={["350px", "initial"]}
                  mx="auto"
                >
                  <Box width="100%" height="100%" minHeight="150px" bg="grayLight">
                    {offer.offerImage && <CustomImage img={offer.offerImage} />}
                  </Box>
                </Box>
                <Box
                  pr={[0, 2, 0, 2]}
                  sx={{
                    flex: "1",
                    borderWidth: ["2px 0 0 0", "0", "2px 0 0 0", "2px 0 0 0", "0"],
                    borderStyle: "solid",
                    borderColor: "primary"
                  }}
                >
                  <Flex height="100%" justifyContent="center" alignItems="center">
                    {offer.offerContent && <Markdown>{offer.offerContent}</Markdown>}
                  </Flex>
                </Box>
              </Flex>
              <Flex bg="primary" p={5} justifyContent="center">
                <Flex alignItems="center" flexDirection={["column", "row", "column", "row"]}>
                  <Text fontWeight="bold" fontSize={6} color="black" px={2}>
                    {parseInt(offer.offerPrice).toLocaleString()} F HT
                  </Text>
                  {offer.offerBtnLabel && (
                    <Box px={2}>
                      <Link href={offer.offerLink}>
                        <Button variant="white">
                          <Text>{offer.offerBtnLabel}</Text>
                        </Button>
                      </Link>
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Box>
          </Box>
        ))}
      </Flex>
      {data.conditions && (
        <Box mt={[4, 4, 6]} fontSize="13px" textAlign="center">
          <Text>{data.conditions}</Text>
        </Box>
      )}
    </Wrapper>
  );
};

Offers.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    intro: PropTypes.any,
    conditions: PropTypes.any,
    offers: PropTypes.any
  })
};

export default Offers;

export const query = graphql`
  fragment OffersBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    intro
    conditions
    offers {
      offerTitle
      offerDuration
      offerContent
      offerImage {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      offerPrice
      offerLink
      offerBtnLabel
    }
  }
`;
