
const apiKey = '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';
const listId = 9; 

const sendinblue = (Email, success, error) => {
    // fetch post request
    fetch('https://api.sendinblue.com/v3/contacts/lists/'+listId+'/contacts/add', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey
        },
        body: JSON.stringify({
            emails: [Email],
        })
    })
    .then(res => res.json())
    .then(data => {
        success(data);
    }
    )
    .catch(err => error(err)); 
}

export default sendinblue;